import { DecimalPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { JourneyService } from '@core/services/journey.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Connectors } from '@nlu-settings/enums/connectors.enum';
import { SupportModalComponent } from '@shared/components/support-modal/support-modal.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { BillingService } from '@billing/services/billing.service';
import { UserSettingsModalComponent } from 'src/app/components/modals/user-settings-modal/user-settings-modal.component';

@Component({
  selector: 'consumption-card',
  templateUrl: './consumption-card.component.html',
  styleUrls: ['./consumption-card.component.scss'],
})
export class ConsumptionCardComponent implements OnInit, OnDestroy {
  @Input() context: 'home' | 'billing';
  @ViewChild('owlCar') public owlCar;

  subscriptions: Object = {};
  billingStats: any = [];

  cardCarouselOptions: OwlOptions = {
    dots: false,
    mouseDrag: false,
  };

  get nluConfigured() {
    return this.journeyService.isProductConfigured(['ES', 'CX']);
  }

  constructor(
    private billingService: BillingService,
    private modalService: NgbModal,
    private journeyService: JourneyService,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit() {
    if (this.context === 'home') {
      this.cardCarouselOptions = {
        ...this.cardCarouselOptions,
        responsive: {
          0: { items: 1 },
          400: { items: 2 },
        },
      };
    } else if (this.context === 'billing') {
      this.cardCarouselOptions = { ...this.cardCarouselOptions, items: 4, dots: true };
    }

    this.subscriptions['JourneySubscription'] = this.journeyService.journey$.subscribe(() => {
      if (this.subscriptions['Consumptions'] instanceof Subscription) this.subscriptions['Consumptions'].unsubscribe();
      this.billingStats = [];
      if (this.nluConfigured) this.getConsumptions();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  async getConsumptions() {
    const endDate = new Date().setHours(23, 59, 59, 999);
    const startDate = new Date().setHours(0, 0, 0, 0);
    const params = {
      days: 0,
      startDate,
      endDate,
    };

    this.subscriptions['Consumptions'] = this.billingService.getBillingConsumtions(params.startDate, params.endDate).subscribe((data) => {
      this.billingStats = data.map((chartData) => ({
        ...chartData,
        unit: this.getChartUnit(chartData.name),
        tooltips: this.getChartTooltip(chartData),
      }));
    });
  }

  private getChartUnit(name) {
    switch (name) {
      case Connectors.DIALOGFLOW_AUDIO_CX:
      case Connectors.DIALOGFLOW_AUDIO_ES:
      case Connectors.DIALOGFLOW_CX:
      case Connectors.DIALOGFLOW_ES:
        return 'Interactions';
      case Connectors.DATA_LOSS_PREVENTION:
      case Connectors.TRANSLATE:
        return 'Characters';
      default:
        return 'Requests';
    }
  }

  private getChartTooltip(chartData) {
    if (chartData.name === Connectors.DATA_LOSS_PREVENTION) {
      return [
        {
          title: this.getChartUnit(chartData.name),
          labels: [`Remaining: ${this.decimalPipe.transform(chartData.remaining, '1.0-0')}`],
        },
        {
          title: this.getChartUnit(chartData.name),
          labels: [
            `Inspected: ${this.decimalPipe.transform(chartData.characterInspected, '1.0-0')}`,
            `Trasformed: ${this.decimalPipe.transform(chartData.characterTrasformed, '1.0-0')}`,
          ],
        },
      ];
    } else {
      return [
        {
          title: this.getChartUnit(chartData.name),
          labels: [`Remaining: ${this.decimalPipe.transform(chartData.remaining, '1.0-0')}`],
        },
        {
          title: this.getChartUnit(chartData.name),
          labels: [`Used: ${this.decimalPipe.transform(chartData.total - chartData.remaining, '1.0-0')}`],
        },
      ];
    }
  }

  modalSupport() {
    const modalRef = this.modalService.open(SupportModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.support = { requestType: 'Quota Increase' };
  }

  modalUserSettings() {
    const modalRef = this.modalService.open(UserSettingsModalComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.settingsMenu = 'email';
  }
}
