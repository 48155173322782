<div class="box_wrapper">
    <div class="dashboard_chunk">
        <div class="row">
            <div class="col">
                <h6 class="chunk_title">Consumption</h6>
            </div>
            <div class="col-3 d-flex justify-content-end" *ngIf="billingStats.length > 1 && context === 'home'">
                <div class="btn-group carousel_nav">
                    <button class="btn brand_light" (click)="owlCar.prev()"><i class="fas fa-angle-left" aria-hidden="true"></i></button>
                    <button class="btn brand_light" (click)="owlCar.next()"><i class="fas fa-angle-right" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
        <div class="chunk_body">
            <ng-template [spinnerLoader]="subscriptions['Consumptions']?.closed"></ng-template>

            <div *ngIf="nluConfigured && billingStats.length > 0 && subscriptions['Consumptions']?.closed === true; else warningTemplate">
                <owl-carousel-o [options]="cardCarouselOptions" #owlCar>
                    <ng-template *ngFor="let item of billingStats" carouselSlide>
                        <circle-progress-bar [remaining]="item.remaining" [total]="item.total" [unit]="item.unit" [mainTitle]="item.name" [tooltips]="item.tooltips"></circle-progress-bar>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <ng-template #warningTemplate>
                <div class="row">
                    <div class="col-12">
                        <div class="alert alert-warning" role="alert">No active consumption services</div>
                    </div>
                </div>
            </ng-template>
        </div>
        <footer class="chunk_bottom">
            <button type="button" class="btn alert_settings" (click)="modalUserSettings()" *ngIf="context === 'billing'">Set alert</button>
            <button type="button" class="btn brand_light" (click)="modalSupport()" *ngIf="context === 'billing'">Buy more</button>
            <button type="button" class="btn brand_light" [routerLink]="['/billing']" *ngIf="context === 'home'">View usage</button>
        </footer>
    </div>
</div>
