import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@authentication/components/login/login.component';
import { JourneyRestrictionGuard } from '@core/guards/journey-restriction.guard';
import { LoadingGuard } from '@core/guards/loading.guard';
import { SalesforceComponent } from '@process-flow/components/salesforce/salesforce.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MainComponent } from './components/main/main.component';
import { SessionGuard } from './modules/core/guards/session.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: '',
    component: MainComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: 'process-flow',
        canActivate: [LoadingGuard],
        loadChildren: () => import(`@process-flow/process-flow.module`).then((m) => m.ProcessFlowModule),
      },
      {
        path: 'design/nlu-settings',
        canActivate: [LoadingGuard],
        loadChildren: () => import(`@nlu-settings/nlu-settings.module`).then((m) => m.NluSettingsModule),
      },
      {
        path: 'design/conversation-flow',
        canActivate: [LoadingGuard],
        loadChildren: () => import(`@intent-design/intent-design.module`).then((m) => m.IntentDesignModule),
      },
      { path: 'training', canActivate: [LoadingGuard], loadChildren: () => import(`@training/training.module`).then((m) => m.TrainingModule) },
      { path: 'admin', canActivate: [LoadingGuard], loadChildren: () => import(`@admin/admin.module`).then((m) => m.AdminModule) },
      { path: 'analytics', canActivate: [LoadingGuard], loadChildren: () => import(`@analytics/analytics.module`).then((m) => m.AnalyticsModule) },
      {
        path: 'health-check',
        canActivate: [LoadingGuard],
        loadChildren: () => import(`@health-check/health-check.module`).then((m) => m.HealthCheckModule),
      },
      { path: 'billing', canActivate: [LoadingGuard], loadChildren: () => import(`@billing/billing.module`).then((m) => m.BillingModule) },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      { path: 'marketplace', loadChildren: () => import(`@marketplace/marketplace.module`).then((m) => m.MarketplaceModule) },
      {
        path: 'channels',
        loadChildren: () => import(`@channels/channels.module`).then((m) => m.ChannelsModule),
        canActivate: [LoadingGuard, JourneyRestrictionGuard],
        data: {
          roleRestriction: ['channel.write', 'channel.read'],
          journeyRestriction: [{ fulfillment: false }],
        },
      },
      { path: 'monitoring', loadChildren: () => import(`@monitoring/monitoring.module`).then((m) => m.MonitoringModule) },
      { path: 'versioning', loadChildren: () => import(`@versioning/versioning.module`).then((m) => m.VersioningModule) },
    ],
  },
  {
    path: 'salesforce',
    component: SalesforceComponent,
  },
  { path: 'tone-of-voice', loadChildren: () => import('./modules/tone-of-voice/tone-of-voice.module').then((m) => m.ToneOfVoiceModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
