import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NullValidationHandler, OAuthEvent, OAuthService, OAuthStorage, TokenResponse } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { RxStompService } from 'src/app/modules/authentication/services/rx-stomp.service';
import { SessionExpiredModalComponent } from '../components/session-expired-modal/session-expired-modal.component';
import { authConfig } from '../configs/auth.config';
import { wsRxStompConfig } from '../configs/ws-rx-stomp.config';

const WARNING_BEFORE_TIMEOUT = 5 * 60 * 1000;
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private sessionTimeout;
  private tokenDuration;

  modalTimeout;

  constructor(
    private oauthService: OAuthService,
    private oAuthStorage: OAuthStorage,
    private rxStompService: RxStompService,
    private injector: Injector,
    private router: Router
  ) {}

  initAuth(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.oauthService.configure(authConfig);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();

      this.oauthService
        .loadDiscoveryDocumentAndTryLogin()
        .then(() => {
          if (this.hasValidToken()) {
            this.startWebsocket();
            this.setSessionDuration();
            this.setWarningTimeout(
              this.sessionTimeout - (new Date().getTime() - (this.oauthService.getAccessTokenExpiration() - this.tokenDuration))
            );
          }

          resolve(true);
        })
        .catch((err) => reject(err));
      this.oauthService.events.pipe(filter((e: OAuthEvent) => e.type === 'token_refreshed')).subscribe((e) => {
        this.setSessionDuration();
        this.setWarningTimeout();
        this.startWebsocket();
      });
    });
  }

  logout(): void {
    if (this.oauthService.getIdToken()) {
      this.oauthService.logOut();
    } else {
      this.router.navigate(['/login']);
    }
  }

  private startWebsocket() {
    if (!this.rxStompService.active) {
      this.rxStompService.configure(wsRxStompConfig(this.oauthService));
      this.rxStompService.activate();
    }
  }

  hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }
  refreshToken(): Promise<TokenResponse> {
    return this.oauthService.refreshToken();
  }

  changePassword() {
    this.oauthService.initLoginFlow('', { kc_action: 'UPDATE_PASSWORD' });
  }
  private setSessionDuration() {
    this.sessionTimeout = parseInt(this.oAuthStorage.getItem('refresh_expires_in'), 10) * 1000;
    this.tokenDuration = parseInt(this.oAuthStorage.getItem('expires_in'), 10) * 1000;
  }
  private setWarningTimeout(timeToSessionEnd = this.sessionTimeout) {
    clearTimeout(this.modalTimeout);
    const timeToWarning = timeToSessionEnd - WARNING_BEFORE_TIMEOUT;
    const sessionTimeout = new Date().getTime() + timeToSessionEnd;

    this.modalTimeout = setTimeout(() => {
      const modalService = this.injector.get<NgbModal>(NgbModal);

      const modalRef = modalService.open(SessionExpiredModalComponent);
      modalRef.componentInstance.sessionTimeout = sessionTimeout;
      modalRef.result.then(() => {
        if (new Date().getTime() > sessionTimeout) {
          this.logout();
        }
      });
    }, timeToWarning);
  }
}
