import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { JourneyService } from '@core/services/journey.service';
import { AgentType } from 'src/app/enums/agents.enum';
import { Journey } from 'src/app/models/journey.model';



@Directive({
  selector: '[journeyRestriction]'
})
export class JourneyRestrictionDirective implements OnInit {
  @Input() journeyRestriction: Array<Partial<Journey>> | Partial<Journey> | Array<keyof typeof AgentType> | keyof typeof AgentType;
  @Input('journeyRestrictionJourneyId') JourneyId?: number = null;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private journeyService: JourneyService) { }

  ngOnInit() {
    this.journeyService.journey$.subscribe(() => {
      if (this.journeyService.isProductConfigured(this.journeyRestriction, this.JourneyId)) {
        if (!this.viewContainer.length) this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
