export enum Connectors {
  DIALOGFLOW_ES = 'Dialogflow ES',
  DIALOGFLOW_AUDIO_ES = 'Dialogflow Audio ES',
  DIALOGFLOW_CX = 'Dialogflow CX',
  DIALOGFLOW_AUDIO_CX='Dialogflow Audio CX',
  DATA_LOSS_PREVENTION = 'Data Loss Prevention',
  NATURAL_LANGUAGE = 'naturallanguage',
  TEXT_TO_SPEECH = 'texttospeech',
  TRANSLATE = 'Translate',
}
