import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { UserAccountService } from 'src/app/modules/core/services/user-account.service';

@Component({
  selector: 'setting-profile',
  templateUrl: './setting-profile.component.html',
  styleUrls: ['./setting-profile.component.scss'],
})
export class SettingProfileComponent {
  get user() {
    return this.userAccountService.user;
  }
  get roles() {
    return this.user.roles.map((r) => r.name);
  }

  constructor(private authenticationService: AuthenticationService, private userAccountService: UserAccountService) {}

  changePassword() {
    this.authenticationService.changePassword();
  }
}
