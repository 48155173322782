import { Component } from '@angular/core';
import { JourneyService } from '@core/services/journey.service';
import { Observable, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { DashboardService } from '../../../services/dashboard.service';
@Component({
  selector: 'overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss'],
})
export class OverviewCardComponent {
  statistics$: Observable<any> = this.journeyService.journey$.pipe(
    switchMap(() => {
      if (this.journeyService.isProductConfigured(['ES', 'CX'])) {
        return this.dashboardService.getSessionStatistics(new Date(2020, 0, 1, 23, 59, 59).getTime(), new Date().setHours(23, 59, 59, 999)).pipe(
          map((res: any) => ({
            total: res.multiObject.genericConversation.total.value,
            avgMessages: res.multiObject.averageConversationMessage,
            avgDuration: res.multiObject.averageConversationDuration * 60 * 1000,
          })),
          startWith(null)
        );
      } else {
        return of({});
      }
    }),
    shareReplay(1)
  );

  constructor(private dashboardService: DashboardService, private journeyService: JourneyService) {}
}
