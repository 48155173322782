import { Component, OnInit } from '@angular/core';
import { JourneyService } from '@core/services/journey.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(private journeyService: JourneyService) {}
  ngOnInit(): void {
    this.journeyService.setSessionAgentLang('all');
  }
}
