import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LicenseService } from '@core/services/license.service';
import { Products } from 'src/app/models/products.model';


@Directive({
  selector: '[licenseRestriction]',
})
export class LicenseRestrictionDirective implements OnInit {
  @Input() licenseRestriction: string;
  @Input('licenseRestrictionItemType') itemType: keyof Products;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private licenseService: LicenseService) {}

  ngOnInit() {
    this.licenseService.getSessionLicense().subscribe(() => {
      if (!this.licenseService.isPurchased(this.licenseRestriction, this.itemType)) {
        this.viewContainer.clear();
      } else {
        if (!this.viewContainer.length) this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }
}
