import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getSessionStatistics(startDate: number, endDate: number) {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()),
    };

    return this.http.get(`${environment.API.orchestrator_analysis}/home/sessions`, httpOptions);
  }

  getTrainingStatistics(startDate: number, endDate: number) {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()),
    };

    return this.http.get(`${environment.API.orchestrator_analysis}/home/conversation/training`, httpOptions);
  }

  getConversationsStatistics(startDate: number, endDate: number, tags?: string) {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()).set('fillEmptyDate', 'true'),
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/home/generic/conversations`, httpOptions);
  }

  getPercentageAgentAutonomy(startDate: number, endDate: number) {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()),
    };

    return this.http.get(`${environment.API.orchestrator_analysis}/home/percentage/agentAutonomy`, httpOptions);
  }

  getAgentChannels(startDate: number, endDate: number) {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()),
    };

    return this.http.get(`${environment.API.orchestrator_analysis}/home/percent/agentChannel`, httpOptions);
  }

  sendSupportRequest(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/support`, data);
  }
}
