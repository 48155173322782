import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JourneyService } from '@core/services/journey.service';
import { NotificationService } from '@shared/services/notification.service';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JourneyRestrictionGuard implements CanActivate {
  journeySubscription: Subscription;
  routeSubscription: Subscription;

  constructor(private router: Router, private journeyService: JourneyService, private notificationService: NotificationService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.routeSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      if (this.journeySubscription instanceof Subscription) {
        this.journeySubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
      }
    });

    return new Promise<boolean>((resolve, reject) => {
      this.journeySubscription = this.journeyService.journey$.subscribe(() => {
        if (this.journeyService.isProductConfigured(next.data.journeyRestriction)) {
          resolve(true);
        } else {
          this.router.navigate(['']);
          this.notificationService.showToast('Your Journey is not configured for access this section', { type: 'warning' });
          reject(false);
        }
      });
    });
  }
}
