import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyService } from '@core/services/journey.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const interceptHandlers: Array<string> = [
  `${environment.API.orchestrator_configurator}/intents`,
  `${environment.API.orchestrator_configurator}/intents/training`,
  `${environment.API.orchestrator_configurator}/intents/parameters`,
  `${environment.API.orchestrator_configurator}/intents/parameters/deleteAll`,
  `${environment.API.orchestrator_configurator}/intents/flow`,
  `${environment.API.orchestrator_analysis}/conversations/view`,
  `${environment.API.orchestrator_analysis}/messages/view/history`,
  `${environment.API.orchestrator_analysis}/health/intent`,
  `${environment.API.orchestrator_analysis}/health/entity`,
  `${environment.API.orchestrator_analysis}/health/agent`,
  `${environment.API.orchestrator_analysis}/health/flow`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/trend/fallbacks/total`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/percente/fallback/messages`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/requestsForAnOperator`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/trend/intents/total`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/agentAutonomy`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/media/dialogflow/confidence`,
  `${environment.API.orchestrator_analysis}/analytics/average/feedback`,
  `${environment.API.orchestrator_analysis}/analytics/percent/precision`,
  `${environment.API.orchestrator_analysis}/analytics/percent/recall`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/precisionAndRecall`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/precisionAndRecallForTable`,
  `${environment.API.orchestrator_configurator}/analytics/trustedIntents`,
  `${environment.API.orchestrator_configurator}/entities/getAll`,
  `${environment.API.orchestrator_configurator}/regression`,
  `${environment.API.orchestrator_configurator}/pageElementResponse/flowList`,
  `${environment.API.orchestrator_configurator}/pageElementResponse/elementResponse`,
  `${environment.API.orchestrator_configurator}/pageElementResponse/groupResponse`,
  `${environment.API.orchestrator_configurator}/pageElementResponse/updateResponse`,
  `${environment.API.orchestrator_configurator}/untrustedConfiguration`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/generic/conversations`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/single/interactions/total`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/multiple/interactions/total`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/media/conversations/messages`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/media/duration/conversations`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/media/duration/conversations`,
  `${environment.API.orchestrator_analysis}/analytics/percent/agentChannel`,
  `${environment.API.orchestrator_analysis}/analytics/percent/tags`,
  `${environment.API.orchestrator_analysis}/analytics/percent/lang`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/percente/fallback/messages`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/engagement/fallbacks`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/engagement/intents`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/engagement/pages`,
  `${environment.API.orchestrator_analysis}/analytics/graphics/engagement/flows`,
  `${environment.API.orchestrator_configurator}/tags/getAll`,
  `${environment.API.orchestrator_configurator}/flow/retrieveCxRouteGroup`,
  `${environment.API.orchestrator_configurator}/flow/createRouteGroup`,
  `${environment.API.orchestrator_configurator}/flow/getFullHierarchy`,
];

@Injectable()
export class AgentInterceptor implements HttpInterceptor {
  constructor(private journeyService: JourneyService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      interceptHandlers.indexOf(req.url) !== -1 &&
      !req.params.has('agentLang') &&
      this.journeyService.agentLang &&
      this.journeyService.agentLang !== 'all'
    ) {
      return next.handle(
        req.clone({
          params: req.params.set('agentLang', this.journeyService.agentLang),
        })
      );
    } else if (req.params.has('agentLang') && req.params.get('agentLang') === 'none') {
      return next.handle(
        req.clone({
          params: req.params.delete('agentLang'),
        })
      );
    }

    return next.handle(req);
  }
}
