import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  
  constructor(private http: HttpClient) {}

  getBillingUsers(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/planning/billing`);
  }

  getNotifiedUser() {
    return this.http.get(`${environment.API.orchestrator_configurator}/planning/notifiedUser`);
  }

  updateNotifiedUser(data: any, params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('enabled', params.enabled.toString()),
    };

    return this.http.put(`${environment.API.orchestrator_configurator}/planning/notifiedUser`, data, httpOptions);
  }

  getChatStatus() {
    return this.http.get<any>(`${environment.API.orchestrator_chat}/actuator/health`).pipe(
      map((res) => {
        if (res.components && res.components.redis) {
          res.components.cache = { ...res.components.redis };
          delete res.components.redis;
        }
        return res;
      }),
      catchError((res) => {
        const body = res.error;
        if (body.components && body.components.redis) {
          body.components.cache = { ...body.components.redis };
          delete body.components.redis;
        }
        return of(body);
      })
    );
  }
  getAnalysisStatus() {
    return this.http.get(`${environment.API.orchestrator_analysis_v2}/actuator/health`).pipe(catchError((res) => of(res.error)));
  }
  getConfiguratorStatus() {
    return this.http.get(`${environment.API.orchestrator_configurator}/actuator/health`).pipe(catchError((res) => of(res.error)));
  }
  getWhatsAppStatus() {
    return this.http.get(`${environment.API.channel_whatsapp}/health-check/health-check`).pipe(catchError((res) => of(res.error)));
  }
  getVivochaStatus() {
    const body = {
      event: 'continue',
      settings: {
        engine: {
          type: 'tellya',
        },
      },
    };
    return this.http.post(`${environment.API.channel_vivocha}`, body).pipe(map(() => ({ status: 'UP' })));
  }
  getTimrccStatus() {
    return this.http.get(`${environment.API.channel_timrcc}/health-check/health-check`);
  }

  restartLivepersonAgent(agentNames: string[]) {
    let httpOptions;
    if (agentNames && agentNames.length > 0) {
      httpOptions = {
        params: {
          agent: agentNames,
        },
      };
    }
    return this.http.post(`${environment.API.orchestrator_configurator}/liveperson/restartAgents`, null, httpOptions);
  }

  getAgentHealth(agentNames?: string[]): Observable<any> {
    let httpOptions;
    if (agentNames && agentNames.length > 0) {
      httpOptions = {
        params: {
          agent: agentNames,
        },
      };
    }
    return this.http.get(`${environment.API.orchestrator_configurator}/liveperson/checkAgentsStatus`, httpOptions).pipe(
      map((res: any) => {
        let retVal = { status: res.status, agents: {} };
        res.agents.forEach((agent) => {
          retVal.agents[agent.agent] = { status: agent.status };
        });
        return retVal;
      }),
      catchError(() => {
        return of({ status: 'UP', agents: {} });
      })
    );
  }

  getLivepersonStatus() {
    const healthCheck = this.http.get(`${environment.API.channel_liveperson}/health-check/health-check`);
    const agentHealth = this.getAgentHealth();

    return zip(healthCheck, agentHealth).pipe(
      map(([healthCheckRes, agentHealthRes]: any) => {
        healthCheckRes.status = healthCheckRes.status === 'UP' && agentHealthRes.status === 'UP' ? 'UP' : 'DOWN';
        healthCheckRes.agents = agentHealthRes.agents;
        return healthCheckRes;
      })
    );
  }
  getFacebookStatus() {
    return this.http.get(`${environment.API.channel_facebook}/health-check/health-check`);
  }
  getGoogleStatus() {
    return this.http.get(`${environment.API.channel_google}/health-check/health-check`);
  }
  getTeamsStatus() {
    return this.http.get(`${environment.API.channel_teams}/health-check/health-check`);
  }

  getTelegramStatus() {
    return this.http.get(`${environment.API.channel_telegram}/health-check/health-check`);
  }

  getConsoleStatus() {
    const httpOptions = {
      responseType: 'text' as any,
    };

    return this.http.get(`/`, httpOptions).pipe(map(() => ({ status: 'UP' })));
  }

  getLogsConnectors() {
    return this.http.get<Array<string>>(`${environment.API.orchestrator_analysis}/gcplogging/getConnectors`);
  }
  getLogs(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('pageSize', pageSize.toString()).set('pageIndex', pageNumber.toString()),
    };

    const allowedParams = ['connectors', 'startDate', 'endDate', 'severity', 'textLog'];
    for (const key of allowedParams) {
      if (params[key] !== null && params[key] !== undefined) {
        if (key === 'connectors' || key === 'severity') {
          params[key].forEach((c) => {
            httpOptions.params = httpOptions.params.append(key, `${c.toString()}`);
          });
        } else {
          httpOptions.params = httpOptions.params.append(key, params[key].toString());
        }
      }
    }
    return this.http.get(`${environment.API.orchestrator_analysis}/gcplogging`, httpOptions);
  }
}
