import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';

const WORD_BREAKERS = ['^', '\\s', '\\.', ',', ';', '$', "'", '"', '\\!', '\\?'];

@Directive({
  selector: '[entitySelection]',
  standalone: true,
})
export class EntitySelectionDirective {
  @Input('entitySelection') entityJson: any;
  @Input() userQuery: string;
  @Input() updateEntities: Subject<any> = new Subject<any>();
  @Output() onSelectEntity = new EventEmitter();

  @HostListener('mouseup', ['$event'])
  onMouseup($event) {
    if ($event.view.getSelection().toString() && !$event.view.getSelection().focusNode.nodeValue.includes($event.view.getSelection().toString())) {
      $event.view.getSelection().empty();
      return;
    }

    if ($event.view.getSelection().toString()) {
      const relativeOffset =
        $event.view.getSelection().getRangeAt(0).getBoundingClientRect().left - this.el.nativeElement.getBoundingClientRect().left;
      const selectionRegex = new RegExp(
        `(?<=${WORD_BREAKERS.join('|')})[^${WORD_BREAKERS.join('')}]*${$event.view.getSelection().toString()}[^${WORD_BREAKERS.join(
          ''
        )}]*(?=${WORD_BREAKERS.join('|')})`
      );
      const [textSelected] = this.el.nativeElement.innerText.match(selectionRegex);

      this.onSelectEntity.emit({
        value: textSelected,
        offset: relativeOffset + $event.view.getSelection().getRangeAt(0).getBoundingClientRect().width / 2,
      });
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event) {
    let entitiesEdited: Array<string> = [];
    $event.target.querySelectorAll('span.entity').forEach((element) => {
      this.entityJson[element.dataset.key] = element.textContent;
      entitiesEdited.push(element.dataset.key);
    });
    this.checkEditableEntities(entitiesEdited);
  }

  constructor(private el: ElementRef, private render: Renderer2) {}

  ngOnInit() {
    this.updateEntities.asObservable().subscribe(($event) => {
      this.markEntities($event);
    });
  }

  ngAfterViewInit() {
    this.markEntities(this.entityJson);
  }

  markEntities(entities: any) {
    this.render.setProperty(this.el.nativeElement, 'innerHTML', this.userQuery);

    if (!entities || Object.keys(entities).length === 0) return;

    let colorIndex = 0;
    for (const key in entities) {
      if (entities.hasOwnProperty(key) && (entities[key].length > 0 || typeof entities[key] === 'object')) {
        if (typeof entities[key] !== 'object') {
          const cleanPhrase = entities[key].replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          const replaceReg = new RegExp(`((?<=${WORD_BREAKERS.join('|')})${cleanPhrase}(?=${WORD_BREAKERS.join('|')}))(?![^<]*>|[^<>]*<\/)`, 'gi');
          const replaceCode = this.el.nativeElement.innerHTML;

          this.render.setProperty(
            this.el.nativeElement,
            'innerHTML',
            replaceCode.replace(replaceReg, `<span class="entity matches_${colorIndex}" data-key="${key}">${entities[key]}</span>`)
          );
        }

        colorIndex++;
      }
    }

    const entitiesElement = this.el.nativeElement.querySelectorAll('span.entity');

    entitiesElement.forEach((element) => {
      this.render.listen(element, 'click', ($event: any) => {
        this.onSelectEntity.emit({
          value: $event.target.textContent,
          oldKey: $event.target.dataset.key,
          offset: $event.target.offsetLeft + $event.target.offsetWidth / 2,
        });
      });
    });
  }

  checkEditableEntities(entities: Array<string>) {
    for (const key in this.entityJson) {
      if (this.entityJson.hasOwnProperty(key)) {
        if (entities.indexOf(key) === -1) delete this.entityJson[key];
      }
    }
  }
}
