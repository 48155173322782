import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { JourneyService } from '@core/services/journey.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgentLang } from 'src/app/models/agent-lang.type';
import { Chatbot, DEFAULT_SESSION_CHAT_SETTINGS } from '../../../classes/chatbot';

function contextValidator(formGroup: AbstractControl): ValidationErrors | null {
  if (
    (formGroup.get('paramKey').value && !formGroup.get('paramValue').value) ||
    (!formGroup.get('paramKey').value && formGroup.get('paramValue').value)
  ) {
    return { paramRequired: true };
  }
  return null;
}
@Component({
  selector: 'app-chat-settings-modal',
  templateUrl: './chat-settings-modal.component.html',
  styleUrls: ['./chat-settings-modal.component.scss'],
})
export class ChatSettingsModalComponent implements OnInit {
  @Input() botSession: Chatbot;

  settingsForm = this.fb.group<{
    customData: FormArray<FormGroup<any>> | FormArray<FormControl<unknown>>;
    parametersMap: FormArray<FormControl<unknown>>;
    contexts: FormArray<FormControl<unknown>>;
    outputAudio: FormControl<boolean>;
    translateResult: FormControl<boolean>;
    selectedLanguage: FormControl<string>;
    journeyCode?: FormControl<string>;
  }>({
    customData: this.fb.array([]),
    parametersMap: this.fb.array([]),
    contexts: this.fb.array([]),
    outputAudio: this.fb.control(false),
    translateResult: this.fb.control(false),
    selectedLanguage: this.fb.control('', Validators.required),
  });

  get sessionData() {
    return this.settingsForm.get('customData') as FormArray;
  }
  get parametersMap() {
    return this.settingsForm.get('parametersMap') as FormArray;
  }

  get contexts() {
    return this.settingsForm.get('contexts') as FormArray;
  }

  getContextForm(removeOnSubmit = false, name = '', lifespan = '', paramKey = '', paramValue = '') {
    return this.fb.group(
      {
        removeOnSubmit: [removeOnSubmit, Validators.required],
        name: [name, Validators.required],
        lifespan: [lifespan, Validators.required],
        paramKey: [paramKey],
        paramValue: [paramValue],
      },
      { validators: contextValidator }
    );
  }

  getParamForm(removeOnSubmit = false, key = '', value = '') {
    return this.fb.group({
      key: [key, Validators.required],
      value: [value, Validators.required],
      removeOnSubmit: [removeOnSubmit, Validators.required],
    });
  }
  settingsSubmitted = false;
  agentSubscription;
  languages: AgentLang[];

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private journeyService: JourneyService) {}

  ngOnInit() {
    this.subscribeForms();

    this.settingsForm.patchValue({
      outputAudio: this.botSession.outputAudio,
      translateResult: this.botSession.translateResult,
      selectedLanguage: this.botSession.sessionLang,
    });

    this.processFormArray(this.botSession);
    this.languages = this.journeyService.journey.agent?.supportedLang;

    if (this.journeyService.journey.dispatcher) {
      this.settingsForm.addControl('journeyCode', this.fb.control(this.botSession.journeyCode || '', Validators.required));
    }
  }

  subscribeForms() {
    this.settingsForm.get('outputAudio').valueChanges.subscribe((value) => {
      if (value) {
        this.settingsForm.get('translateResult').disable({ emitEvent: false });
      } else {
        this.settingsForm.get('translateResult').enable({ emitEvent: false });
      }
    });

    this.settingsForm.get('translateResult').valueChanges.subscribe((value) => {
      if (value) {
        this.settingsForm.get('outputAudio').disable({ emitEvent: false });
      } else {
        this.settingsForm.get('outputAudio').enable({ emitEvent: false });
      }
    });
  }

  processFormArray(settings) {
    this.settingsForm.setControl(
      'customData',
      this.fb.array(Object.keys(settings.sessionUserData).map((key) => this.getParamForm(false, key, settings.sessionUserData[key])))
    );
    this.settingsForm.setControl(
      'parametersMap',
      this.fb.array(settings.parametersMap.map((p) => this.getParamForm(p.removeOnSubmit, p.key, p.value)))
    );
    this.settingsForm.setControl(
      'contexts',
      this.fb.array(settings.contexts.map((c) => this.getContextForm(c.removeOnSubmit, c.name, c.lifespan, c.paramKey, c.paramValue)))
    );
  }

  resetSettings() {
    this.processFormArray(DEFAULT_SESSION_CHAT_SETTINGS);
  }

  saveSettings(refreshChat) {
    this.settingsSubmitted = true;

    if (this.settingsForm.invalid) return;

    const sessionSettings = {
      sessionUserData: this.sessionData.value.reduce((acc, d) => ({ ...acc, [d.key]: d.value }), {}),
      parametersMap: this.parametersMap.value,
      contexts: this.contexts.value,
      outputAudio: this.settingsForm.get('outputAudio').value,
      translateResult: this.settingsForm.get('translateResult').value,
      sessionLang: this.settingsForm.get('selectedLanguage').value,
      journeyCode: this.settingsForm.get('journeyCode') ? this.settingsForm.get('journeyCode').value : null,
    };

    this.activeModal.close();
    if (refreshChat) {
      this.botSession.restoreChatbot(sessionSettings);
    } else {
      this.botSession.setSettings(sessionSettings);
    }
  }
}
