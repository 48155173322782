import { AnalyticsService } from '@analytics/services/analytics.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { JourneyService } from '@core/services/journey.service';
import { Observable, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
  selector: 'training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss'],
})
export class TrainingCardComponent implements OnInit, OnDestroy {
  subscriptions: Object = {};
  statistics$: Observable<any> = this.journeyService.journey$.pipe(
    switchMap(() => {
      if (this.journeyService.isProductConfigured(['ES', 'CX'])) {
        return this.dashboardService.getTrainingStatistics(new Date(2020, 0, 1, 23, 59, 59).getTime(), new Date().setHours(23, 59, 59, 999)).pipe(
          map((res: any) => ({
            assigned: res.multiObject.assigned,
            fallbacks: res.multiObject.fallbacks,
            notAnalyzed: res.multiObject.notAnalyzed,
          })),
          startWith(null)
        );
      } else {
        return of({});
      }
    }),
    shareReplay(1)
  );
  conversationsTrainingPercentageIntents: number;
  conversationsTrainingPercentagAgentAutonomy: number;

  constructor(private dashboardService: DashboardService, private journeyService: JourneyService, private analyticsService: AnalyticsService) {}

  ngOnInit() {
    this.subscriptions['JourneySubscription'] = this.journeyService.journey$.subscribe(() => {
      this.getConversationsTrainingStatistics();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getConversationsTrainingStatistics() {
    const params = {
      startDate: new Date(2020, 0, 1, 0, 0, 0).getTime(),
      endDate: new Date().setHours(23, 59, 59, 999),
      tags: null,
    };

    if (this.journeyService.isProductConfigured(['ES', 'CX'])) {
      this.getConversationsTrainingPercentageIntents(params);
      this.getConversationsTrainingPercentagAgentAutonomy(params);
    } else {
      this.conversationsTrainingPercentageIntents = null;
      this.conversationsTrainingPercentagAgentAutonomy = null;
    }
  }

  getConversationsTrainingPercentagAgentAutonomy(params: any) {
    this.subscriptions['ConversationsTrainingPercentagAgentAutonomy'] = this.dashboardService
      .getPercentageAgentAutonomy(params.startDate, params.endDate)
      .subscribe((response: any) => {
        this.conversationsTrainingPercentagAgentAutonomy = isNaN(response.singleResult.percAgentAutonomy)
          ? 0
          : response.singleResult.percAgentAutonomy;
      });
  }

  getConversationsTrainingPercentageIntents(params: any) {
    this.subscriptions['ConversationsTrainingPercentageIntents'] = this.analyticsService.getTrustedIntents().subscribe((response: any) => {
      this.conversationsTrainingPercentageIntents = isNaN(response.percTrusted) ? 0 : response.percTrusted;
    });
  }
}
