import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgentLanguagesComponent } from '@core/components/agent-languages/agent-languages.component';
import { JourneyRestrictionDirective } from '@core/directives/journey-restriction.directive';
import { LicenseRestrictionDirective } from '@core/directives/license-restriction.directive';
import { RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UrlSanitizerPipe } from '@shared/pipes/url-sanitizer.pipe';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { CircleProgressBarComponent } from './components/circle-progress-bar/circle-progress-bar.component';
import { CodeEditorComponent } from './components/code-editor/code-editor.component';
import { ConsumptionCardComponent } from './components/consumption-card/consumption-card.component';
import { DatepickerRangeComponent } from './components/datepicker/datepicker-range/datepicker-range.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SpinnerLoaderComponent } from './components/spinner-loader/spinner-loader.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { StepsCompletionComponent } from './components/steps-completion/steps-completion.component';
import { SupportModalComponent } from './components/support-modal/support-modal.component';
import { ToastNotificationComponent } from './components/toast-notification/toast-notification.component';
import { ContainerSelectorDirective } from './directives/container-selector.directive';
import { RestrictCharDirective } from './directives/restrict-char.directive';
import { SpinnerLoaderDirective } from './directives/spinner-loader.directive';
import { TableSortDirective } from './directives/table-sort.directive';
import { AnalyticalFormatterPipe } from './pipes/analytical-formatter';
import { FilterPropertiesPipe } from './pipes/filter-properties.pipe';
import { FullTextSearchPipe } from './pipes/full-text-search.pipe';
import { CodeEditorService } from './services/code-editor.service';
import { NotificationService } from './services/notification.service';

@NgModule({
  declarations: [
    UrlSanitizerPipe,
    SpinnerLoaderDirective,
    TableSortDirective,
    ToastNotificationComponent,
    DatepickerRangeComponent,
    SpinnerLoaderComponent,
    StarRatingComponent,
    StepsCompletionComponent,
    PaginationComponent,
    DoughnutChartComponent,
    CircleProgressBarComponent,
    LineChartComponent,
    BarChartComponent,
    CodeEditorComponent,
    NotificationModalComponent,
    SupportModalComponent,
    FullTextSearchPipe,
    FilterPropertiesPipe,
    AgentLanguagesComponent,
    LicenseRestrictionDirective,
    JourneyRestrictionDirective,
    RoleRestrictionDirective,
    RestrictCharDirective,
    ContainerSelectorDirective,
    ConsumptionCardComponent,
    AnalyticalFormatterPipe,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule, RouterModule, CarouselModule],
  providers: [CodeEditorService, NotificationService],
  exports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    DatepickerRangeComponent,
    StepsCompletionComponent,
    PaginationComponent,
    DoughnutChartComponent,
    CircleProgressBarComponent,
    LineChartComponent,
    BarChartComponent,
    CodeEditorComponent,
    SpinnerLoaderDirective,
    TableSortDirective,
    ToastNotificationComponent,
    FullTextSearchPipe,
    FilterPropertiesPipe,
    AgentLanguagesComponent,
    LicenseRestrictionDirective,
    JourneyRestrictionDirective,
    RoleRestrictionDirective,
    UrlSanitizerPipe,
    RestrictCharDirective,
    ContainerSelectorDirective,
    ConsumptionCardComponent,
    AnalyticalFormatterPipe,
  ],
})
export class SharedModule {}
