import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  onToggleMenu: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  switchMenu(stretchStatus: boolean) {
    this.onToggleMenu.next(stretchStatus);
  }

  onStretchMenu(): Observable<boolean> {
    return this.onToggleMenu.asObservable();
  }

  groupBy(items: Array<any>, groupAttribute: string): any {
    return items.reduce((accumulator, currentValue) => {
      if (currentValue[groupAttribute]) {
        if (!Array.isArray(accumulator[currentValue[groupAttribute]])) accumulator[currentValue[groupAttribute]] = [];
        accumulator[currentValue[groupAttribute]].push(currentValue);
      }
      return accumulator;
    }, {});
  }

  sortBy(items: Array<any>, key: string): any {
    return (items || []).sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }

  downloadFile(data: any, responseType: string, fileName: string) {
    const blob = new Blob([data], { type: responseType });

    let link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  copyToClipboard(item: any) {
    if (typeof item === 'string') {
      navigator.clipboard.writeText(item);
    } else {
      navigator.clipboard.writeText(item.value);
    }
  }

  escapeRegExpChars(string: string | Array<string>): string | Array<string> {
    if (Array.isArray(string)) {
      return string.reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        return accumulator;
      }, []);
    }
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  cloneJson(object: any) {
    return JSON.parse(JSON.stringify(object));
  }
}
