import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ConsumptionAlertModalComponent } from '@billing/components/consumption-alert-modal/consumption-alert-modal.component';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient, private injector: Injector) { }

  getRequestStats(connectorName: string, days: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('days', days.toString()),
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/billing/${connectorName}`, httpOptions);
  }

  getConsumptionAlerts() {
    return this.http.get(`${environment.API.orchestrator_configurator}/thresholdReport`).pipe(
      tap((response: Array<any>) => {
        const serviceExpiring = response.filter((service) => service.alert);

        if (serviceExpiring.length) {
          const modalService = this.injector.get<NgbModal>(NgbModal);

          const modalRef = modalService.open(ConsumptionAlertModalComponent);
          modalRef.componentInstance.services = serviceExpiring;
        }
      })
    );
  }

  getBillingConsumtions(startDate: number, endDate: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()),
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/billing/consumption`, httpOptions);
  }
}
