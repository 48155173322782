import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CommonApiService } from '@shared/services/common-api.service';
import { SharedModule } from '@shared/shared.module';
import { Subject } from 'rxjs';
import { AgentType } from 'src/app/enums/agents.enum';
import { Languages } from 'src/app/enums/languages.enum';
import { UserAccountService } from 'src/app/modules/core/services/user-account.service';
import { EntitySelectionDirective } from 'src/app/standalone/directives/entity-selection.directive';

@Component({
  selector: 'chat-interaction',
  templateUrl: './chat-interaction.component.html',
  styleUrls: ['./chat-interaction.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [EntitySelectionDirective, SharedModule],
})
export class ChatInteractionComponent implements OnInit, OnDestroy {
  @Input() viewFilters = {
    parameters: true,
    page: true,
    flow: true,
    contextOut: true,
    response: true,
    sentiment: true,
    status: true,
  };
  forbiddenTraining = false;
  @Input() uneditableView? = false;

  private _interaction;
  get interaction() {
    return this._interaction;
  }
  @Input() set interaction(interaction) {
    this._interaction = interaction;
    if (this._interaction) {
      setTimeout(() => this.onUpdateEntities.next(this.interaction.editedDfEntityJson));
    }
  }
  @Input() agentBotType: AgentType;
  @Input() intents;

  @ViewChild('popIntent') popIntent: NgbPopover;
  @ViewChild('popEntity') popEntity: NgbPopover;
  entityPopOffset: string;
  onUpdateEntities: Subject<any> = new Subject<any>();

  Languages = Languages;
  subscriptions: Object = {};
  entities: Array<any> = [];
  intentFilter;
  entityFilter;

  get showContextOut() {
    return this.agentBotType === AgentType.ES;
  }

  get showPage() {
    return this.agentBotType === AgentType.CX;
  }

  get showFlow() {
    return this.agentBotType === AgentType.CX;
  }

  get showParameter() {
    return (
      Object.keys(this.interaction.editedDfEntityJson || {}).length > 0 ||
      Object.keys(this.getContextParameters(this.interaction.dfOutputContextJson) || {}).length > 0
    );
  }

  showEvent(interaction) {
    return interaction.dfEventName;
  }
  showUserSays(interaction) {
    return interaction.userQuery !== null && (this.agentBotType === AgentType.CX || !interaction.dfEventName);
  }

  trainableIntent(interaction) {
    return (
      ((this.agentBotType === AgentType.ES && !interaction.dfEventName) || (this.agentBotType === AgentType.CX && interaction.userQuery !== null)) &&
      !this.uneditableView &&
      interaction.forwardNlu == null
    );
  }

  showIntent(interaction) {
    return this.agentBotType === AgentType.ES || (this.agentBotType === AgentType.CX && (interaction.fallback || interaction.userQuery !== null));
  }

  constructor(private commonApiService: CommonApiService, private userAccountService: UserAccountService) {}

  ngOnInit() {
    if (!this.userAccountService.isAuthorized('training.write')) {
      this.forbiddenTraining = true;
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getEntities(intentName: string, lang: string) {
    this.subscriptions['Entities'] = this.commonApiService.getEntities({ intentName: intentName, agentLang: lang }).subscribe((response: any) => {
      this.entities = response.singleResult.parameters;
    });
  }

  parseContextName(context: string): string {
    const contextPath = context.split('/');
    return contextPath[contextPath.length - 1];
  }

  getSplittedMessages(outputMessage: string): Array<string> {
    return outputMessage.split('##');
  }

  getContextParameters(outputContextJson: Array<any>) {
    const contextParameters = {};

    outputContextJson?.forEach((context: any) => {
      for (const key in context.parameters) {
        if (
          Object.prototype.hasOwnProperty.call(context.parameters, key) &&
          !this.interaction.editedDfEntityJson[key] &&
          !key.includes('.original') &&
          !contextParameters[key]
        ) {
          contextParameters[key] = context.parameters[key];
        }
      }
    });

    return contextParameters;
  }

  orderBlank() {
    return 0;
  }

  isLastParameter(parametersObject: any, index: number): boolean {
    return Object.keys(parametersObject).length === index + 1;
  }

  togglePopEntity($event: any) {
    if (this.interaction.forwardNlu != null) return;
    this.getEntities(this.interaction.editedDfIntentName, this.interaction.lang);

    this.entityPopOffset = `${$event.offset}px`;

    if (this.popEntity.isOpen()) {
      this.popEntity.close();
    } else {
      this.popEntity.open({ $event });
    }
  }

  togglePopIntent() {
    if (this.popIntent.isOpen()) {
      this.popIntent.close();
    } else {
      this.popIntent.open();
    }
  }

  setIntent(intent: any) {
    this.interaction.editedDfIntentName = intent.name;
    this.interaction.nluPayloadIntent = intent;
    this.popIntent.close();
    this.interaction.editedDfEntityJson = {};
    this.onUpdateEntities.next(this.interaction.editedDfEntityJson);
  }

  setEntity(entityKey: string, entityEvent: any) {
    if (entityEvent.oldKey) delete this.interaction.editedDfEntityJson[entityEvent.oldKey];

    this.interaction.editedDfEntityJson[entityKey] = entityEvent.value;
    this.onUpdateEntities.next(this.interaction.editedDfEntityJson);
    this.popEntity.close();
  }

  removeParameter(interaction: any, parameterKey: any) {
    delete interaction.editedDfEntityJson[parameterKey];
    this.onUpdateEntities.next(interaction.editedDfEntityJson);
  }

  updateUserQuery(target: EventTarget) {
    const textContent = (target as HTMLInputElement).textContent;
    this.interaction.editedUserQuery = textContent;
  }
}
