import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getInteractionHistory(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('pageSize', pageSize.toString()).set('pageIndex', pageNumber.toString()),
    };

    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.sessionId) httpOptions.params = httpOptions.params.append('sessionId', params.sessionId);
    if (params.dfIntentName) httpOptions.params = httpOptions.params.append('dfIntentName', params.dfIntentName);
    if (params.userQuery) httpOptions.params = httpOptions.params.append('userQuery', params.userQuery);
    if (params.rating) httpOptions.params = httpOptions.params.append('operatorRatings', params.rating);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);

    return this.http.get(`${environment.API.orchestrator_analysis}/messages/view/history`, httpOptions);
  }

  getEntities(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('intentName', params.intentName).set('agentLang', params.agentLang),
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/nlu/integrations/intents/parameters`, httpOptions);
  }

  getInteractions(sessionId: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('sessionId', sessionId),
    };

    return this.http.get(`${environment.API.orchestrator_analysis}/messages/view`, httpOptions);
  }

  getIntents(params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('onlyName', params?.onlyName ? 'true' : 'false'),
    };

    if (params?.journeyApiKey) httpOptions.params = httpOptions.params.append('journeyApiKey', params.journeyApiKey);

    return this.http.get(`${environment.API.orchestrator_analysis}/nlu/integrations/intents/search`, httpOptions);
  }

  getAgentsBot(type?: 'DIALOGFLOW_ES' | 'DIALOGFLOW_CX'): Observable<any> {
    let httpOptions;
    if (type) {
      httpOptions = {
        params: new HttpParams().set('type', type),
      };
    }

    return this.http.get(`${environment.API.orchestrator_configurator}/agentbot`, httpOptions);
  }
  getAllFlow(journeyApiKey?: string) {
    const httpOptions = {
      params: new HttpParams(),
    };

    if (journeyApiKey) httpOptions.params = httpOptions.params.append('journeyApiKey', journeyApiKey);
    return this.http.get<any>(`${environment.API.orchestrator_configurator}/flow/list`, httpOptions);
  }

  downloadFile(section: 'analytics' | 'flow' | 'history' | 'intents', filePath: string, fileName: string) {
    const httpOptions = {
      params: new HttpParams().set('fileName', filePath),
      responseType: 'blob' as 'blob',
    };

    return this.http.get(`${environment.API.orchestrator_configurator}/${section}/downloadExport`, httpOptions).pipe(
      tap((response) => {
        this.commonService.downloadFile(response, 'blob', fileName);
      })
    );
  }

  createIntent(data: Object): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/intents`, data);
  }
}
