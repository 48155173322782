import { AfterViewInit, Component, ElementRef, forwardRef, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { first } from 'rxjs/operators';
import { CodeEditorService } from '@shared/services/code-editor.service';

type supportedLang = 'javascript' | 'json' | 'java';
declare const monaco: any;
const themeMap = {
  light: 'vs',
  dark: 'vs-dark',
};
@Component({
  selector: 'code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeEditorComponent),
      multi: true,
    },
  ],
})
export class CodeEditorComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  public _editor: any;
  @ViewChild('editorContainer') _editorContainer: ElementRef;
  @Input() readOnly = false;
  private _language: supportedLang = 'javascript';
  public get language(): supportedLang {
    return this._language;
  }
  @Input() public set language(value: supportedLang) {
    this._language = value;
    if (this._editor) {
      monaco.editor.setModelLanguage(this._editor.getModel(), value);
    }
  }

  private _theme: 'light' | 'dark' = 'light';
  public get theme(): 'light' | 'dark' {
    return this._theme;
  }
  @Input() public set theme(value: 'light' | 'dark') {
    this._theme = value;
    if (this._editor) {
      this._editor.updateOptions({ theme: themeMap[value] });
    }
  }

  private _fontSize = '16px';
  public get fontSize(): string {
    return this._fontSize;
  }
  @Input() public set fontSize(value: string) {
    this._fontSize = value;
    if (this._editor) {
      this._editor.updateOptions({ fontSize: value });
    }
  }
  onChange: Function;
  onTouch: Function;
  _value = ` `;
  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange?.(value);
    this.onTouch?.(value);
  }

  writeValue(value: any) {
    if (value !== undefined && value !== null && this.value !== value) {
      this.value = value;
      // Fix for value change while dispose in process.
      setTimeout(() => {
        if (this._editor) {
          this._editor.setValue(this.value);
        }
      });
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  constructor(private zone: NgZone, private codeEditorService: CodeEditorService) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.initEditor());
  }

  ngOnDestroy() {
    if (this._editor) {
      this._editor.dispose();
      this._editor = undefined;
    }
  }

  private initEditor(): void {
    this.codeEditorService.onLoaded.pipe(first()).subscribe(() => {
      const options = {
        value: this.value.toString(),
        readOnly: this.readOnly,
        language: this.language,
        minimap: { enabled: false },
        theme: themeMap[this.theme],
        fontSize: this.fontSize,
      };
      this._editor = monaco.editor.create(this._editorContainer.nativeElement, options);
      this._editor.onDidChangeModelContent(() => {
        // value is not propagated to parent when executing outside zone.
        this.zone.run(() => {
          const value = this._editor.getValue();

          this.onChange(value);
          this.value = value;
        });
      });

      this._editor.onDidBlurEditorWidget(() => {
        this.onTouch();
      });

      new ResizeObserver(() => this._editor?.layout?.()).observe(this._editorContainer.nativeElement);
    });
  }
}
