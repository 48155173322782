import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyService } from '@core/services/journey.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const interceptConfigurator: Array<string> = [
  `${environment.API.orchestrator_configurator}/analytics/trustedIntents`,
  `${environment.API.orchestrator_configurator}/intents`,
  `${environment.API.orchestrator_configurator}/intents/training`,
  `${environment.API.orchestrator_configurator}/intents/synchronize`,
  `${environment.API.orchestrator_configurator}/intents/parameters`,
  `${environment.API.orchestrator_configurator}/intents/flow`,
  `${environment.API.orchestrator_configurator}/journey/embeddedConfiguration`,
  `${environment.API.orchestrator_configurator}/journey/list/configuration`,
  `${environment.API.orchestrator_configurator}/entities`,
  `${environment.API.orchestrator_configurator}/regression`,
  `${environment.API.orchestrator_configurator}/faq/extractWithFile`,
  `${environment.API.orchestrator_configurator}/liveperson/checkAgentsStatus`,
  `${environment.API.orchestrator_configurator}/liveperson/restartAgents`,
  `${environment.API.orchestrator_configurator}/intents/exportConfiguration`,
  `${environment.API.orchestrator_configurator}/flow/exportConfiguration`,
  `${environment.API.orchestrator_configurator}/flow/page/tags`,
  `${environment.API.orchestrator_configurator}/tags/changeStatus`,
  `${environment.API.orchestrator_configurator}/tags`,
  `${environment.API.orchestrator_configurator}/tags/getByApikey`,
  `${environment.API.orchestrator_configurator}/sync/synchronize`,
  `${environment.API.orchestrator_configurator}/placeholder`,
  `${environment.API.orchestrator_configurator}/flow/list`,
  `${environment.API.orchestrator_configurator}/pageElementResponse/flowList`,
  `${environment.API.orchestrator_configurator}/pageElementResponse/elementResponse`,
  `${environment.API.orchestrator_configurator}/pageElementResponse/updateResponse`,
  `${environment.API.orchestrator_configurator}/pageElementResponse/groupResponse`,
  `${environment.API.orchestrator_configurator}/regression/import`,
  `${environment.API.orchestrator_configurator}/tagUser/getTagTree`,
  `${environment.API.orchestrator_configurator}/untrustedConfiguration`,
  `${environment.API.orchestrator_configurator}/flow/retrieveCxRouteGroup`,
  `${environment.API.orchestrator_configurator}/flow/createRouteGroup`,
  `${environment.API.orchestrator_configurator}/license/getFilteredConnectors`,
  `${environment.API.orchestrator_analysis}/nlu/integrations/intents/search`,
  `${environment.API.orchestrator_configurator}/flow/getFullHierarchy`,
];
const urlMatcher = new RegExp(`${interceptConfigurator.join('|')}`);
@Injectable()
export class JourneyInterceptor implements HttpInterceptor {
  constructor(private journeyService: JourneyService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((req.url.indexOf(environment.API.orchestrator_analysis) !== -1 || urlMatcher.test(req.url)) && !req.params.has('journeyApiKey')) {
      return next.handle(
        req.clone({
          params: req.params.set('journeyApiKey', this.journeyService.journey.apiKey),
        })
      );
    }

    return next.handle(req);
  }
}
