import * as hark from 'hark';
import * as RecordRTC from 'recordrtc';
import { Subject } from 'rxjs';
import { OrchestrateDialog } from '../models/orchestrate-dialog.model';
import { OrchestrateFulfillmentResponse } from '../models/orchestrate-fulfillment-response.model';
import { OrchestrateInteraction } from '../models/orchestrate-interaction.model';
import { OrchestrateMessage } from '../models/orchestrate-message.model';
import { OrchestrateResponse } from '../models/orchestrate-response.model';

export const DEFAULT_SESSION_CHAT_SETTINGS: any = {
  sessionUserData: {
    agentChannel: 6,
  },
  parametersMap: [],
  contexts: [],
  outputAudio: false,
  translateResult: false,
};
export class Chatbot {
  sessionId: string;
  sessionKey: string;
  sessionLang: string;
  journeyCode: string;
  sessionUserData: any;
  parametersMap = [];

  get formattedParametersMap() {
    if (this.parametersMap.length === 0) return null;
    return this.parametersMap.reduce((acc, p) => ({ ...acc, [p.key]: p.value }), {});
  }
  contexts = [];
  outputAudio = false;
  translateResult = false;

  inputMessage = '';
  inputEvent = '';
  get formattedEvent() {
    if (!this.inputEvent) return null;
    return {
      name: this.inputEvent || null,
      data: this.inputEvent ? this.formattedParametersMap : null,
    };
  }

  get formattedContexts() {
    if (this.contexts.length === 0) return null;
    return this.contexts.map(({ name, lifespan, paramKey, paramValue }) => ({
      name,
      lifespan,
      parameters: paramKey ? { [paramKey]: paramValue } : null,
    }));
  }
  files = {};
  dialog: OrchestrateDialog[] = [];

  recorder: any;
  recorderState: 'recording' | 'stopped';
  audioBlobInput: any;
  audioBase64Input: any;

  onRestoreSession: Subject<any> = new Subject<any>();
  onStopRecording: Subject<any> = new Subject<any>();
  currentSettings: any;
  constructor(chatbotKey: string, sessionLang, sessionSettings = DEFAULT_SESSION_CHAT_SETTINGS) {
    this.sessionId = this.generateSessionId();
    this.sessionKey = chatbotKey;
    this.setSettings(sessionSettings, sessionLang);
  }

  setSettings(sessionSettings, sessionLang?) {
    this.currentSettings = sessionSettings;

    this.sessionLang = sessionSettings.sessionLang || sessionLang;
    this.sessionUserData = sessionSettings.sessionUserData;
    this.outputAudio = sessionSettings.outputAudio;
    this.translateResult = sessionSettings.translateResult;
    this.journeyCode = sessionSettings.journeyCode;
    this.contexts = sessionSettings.contexts;
    this.parametersMap = sessionSettings.parametersMap;
  }
  generateSessionId(): string {
    const allowChars = 'ABCDEF0123456789';
    let sessionId = new Date().getTime().toString();

    for (let i = 0; i < 24 && sessionId.length < 24; i++) {
      sessionId += allowChars.charAt(Math.floor(Math.random() * allowChars.length));
    }

    return sessionId;
  }

  restoreChatbot(sessionSettings: any) {
    this.onRestoreSession.next(sessionSettings);
  }

  processOrchestrateResponse(userRequest, orchestrateResponse: OrchestrateResponse, audioInput?: boolean) {
    if (audioInput) this.processAudioInput(orchestrateResponse.results.aiResponse.result.resolvedQuery);

    this.clearUserInput();

    const interaction: OrchestrateInteraction = {
      lang: userRequest.lang,
      dfEventName: userRequest.event ? userRequest.event.name : null,
      userQuery: orchestrateResponse.results.aiResponse.result.resolvedQuery || null,
      dfEntitiesJson: orchestrateResponse.results.aiResponse.result.parameters,
      editedDfEntityJson: orchestrateResponse.results.aiResponse.result.parameters,
      dfIntentName: orchestrateResponse.results.aiResponse.result.metadata.intentName,
      dfOutputContextJson: orchestrateResponse.results.aiResponse.result.contexts,
      dfPageName: orchestrateResponse.results.aiResponse.result.metadata.landedPage?.name,
      dfFlowName: orchestrateResponse.results.aiResponse.result.metadata.flow?.name,
      dfOutputMessage: this.processDebugMessages(orchestrateResponse.results.aiResponse.result.fulfillment.messages),
      dfConfidence: orchestrateResponse.results.aiResponse.result.score,
      dfSentiment: orchestrateResponse.results.naturalLanguageResponse?.sentiment || null,
      dfMagnitude: orchestrateResponse.results.naturalLanguageResponse?.magnitude || null,
      trusted: orchestrateResponse.extraResult.details.trusted,
    };

    const data = {
      interaction,
      apiResponse: orchestrateResponse,
      detectedInteraction: this.processInteraction(interaction),
    };
    if (this.translateResult && orchestrateResponse.results.translateResponse_answer) {
      this.updateDialog('bot', [{ type: 'text', text: orchestrateResponse.results.translateResponse_answer.textResult }], data);
    } else {
      this.updateDialog(
        'bot',
        [
          ...orchestrateResponse.results.aiResponse.result.fulfillment.messages,
          ...(this.getAudioPayload(orchestrateResponse.results.aiResponse.result.fulfillment.audioOutput) || []),
        ],
        data
      );
    }
  }

  processFulfillmentResponse(userRequest, fulfillmentResponse: OrchestrateFulfillmentResponse) {
    this.clearUserInput();
    const rawMessages = fulfillmentResponse.queryResult.fulfillmentMessages || fulfillmentResponse.queryResult.responseMessages || [];
    const messages = rawMessages.map(({ text, payload }) => ({ text: text?.text[0], ...payload }));
    const interaction: OrchestrateInteraction = {
      lang: userRequest.lang,
      dfEventName: userRequest.event?.name,
      userQuery: fulfillmentResponse.queryResult.queryText || fulfillmentResponse.queryResult.text || null,
      dfEntitiesJson: fulfillmentResponse.queryResult.parameters,
      editedDfEntityJson: fulfillmentResponse.queryResult.parameters,
      dfIntentName: fulfillmentResponse.queryResult.intent?.displayName && fulfillmentResponse.queryResult.intent.displayName,
      dfOutputContextJson: fulfillmentResponse.queryResult.outputContexts,
      dfPageName: fulfillmentResponse.queryResult.currentPage?.displayName,
      dfFlowName: this.processFulfillmentFlow(fulfillmentResponse.queryResult.diagnosticInfo),
      dfOutputMessage: this.processDebugMessages(messages),
      dfConfidence: fulfillmentResponse.queryResult.intentDetectionConfidence,
      dfSentiment: null,
      dfMagnitude: null,
      trusted: null,
    };

    const data = {
      interaction,
      apiResponse: fulfillmentResponse,
      detectedInteraction: this.processInteraction(interaction),
    };
    this.updateDialog('bot', [...messages, ...(this.getAudioPayload(fulfillmentResponse.outputAudio) || [])], data);
  }

  updateDialog(source: 'bot' | 'user', messages: Array<OrchestrateMessage>, info?: any) {
    this.dialog.push({ type: source, messages, ...info });
  }

  addInteractionMessage(index, message) {
    this.dialog[index].messages.push(message);
  }

  private processDebugMessages(messages: Array<any>): string {
    return messages
      .filter((m) => m.text)
      .map((m) => m.text)
      .join('##');
  }

  private processFulfillmentFlow(diagnosticInfo: any) {
    if (!diagnosticInfo || !diagnosticInfo['Execution Sequence']) return null;
    for (const sequence of diagnosticInfo['Execution Sequence']) {
      for (const key of Object.keys(sequence)) {
        if (sequence[key].Type === 'STATE_MACHINE' && sequence[key]['StateMachine'] && sequence[key]['StateMachine']['FlowState']) {
          return sequence[key]['StateMachine']['FlowState'].Name;
        }
      }
    }
    return null;
  }

  startRecording() {
    window.navigator.mediaDevices.getUserMedia({ audio: true }).then(async (stream: any) => {
      this.recorder = new RecordRTC.RecordRTCPromisesHandler(stream, {
        type: 'audio',
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
        disableLogs: true,
      });

      await this.recorder.startRecording().then(() => (this.recorderState = 'recording'));

      const speechEvents = hark(stream, {});
      speechEvents.on('stopped_speaking', () => {
        if (!this.recorder) return;
        speechEvents.stop();
        this.stopRecording();
      });
    });
  }

  async stopRecording() {
    await this.recorder.stopRecording().then(() => (this.recorderState = 'stopped'));
    this.audioBlobInput = await this.recorder.getBlob();
    this.audioBase64Input = await this.getAudioBase64(this.audioBlobInput);

    this.onStopRecording.next({ blobUrl: URL.createObjectURL(this.audioBlobInput), audioBase64: this.audioBase64Input });
    this.recorder.destroy();
    this.recorder = null;
  }

  private getAudioBase64(blob: any): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const audioBase64 = (reader.result as string).split(',')[1];
        resolve(audioBase64);
      };
    });
  }

  private getAudioPayload(audio: Blob | string, resolvedQuery = ''): OrchestrateMessage[] {
    if (!audio) return null;
    if (typeof audio === 'string' || audio instanceof String) {
      audio = this.getAudioBlob(audio as string);
    }
    return [
      {
        type: 'audio',
        items: [{ source: URL.createObjectURL(audio), query: resolvedQuery }],
      },
    ];
  }

  private getAudioBlob(base64: string) {
    const byteString = atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const integersArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      integersArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: '' });
  }

  clearUserInput() {
    this.inputMessage = '';
    this.inputEvent = '';
    this.files = {};
    this.audioBlobInput = null;
    this.audioBase64Input = '';
    this.contexts = this.contexts.filter((c) => !c.removeOnSubmit);
    this.parametersMap = this.parametersMap.filter((p) => !p.removeOnSubmit);
  }

  private processAudioInput(resolvedQuery) {
    const payload = this.getAudioPayload(this.audioBlobInput, resolvedQuery);
    this.updateDialog('user', payload);
  }

  private processInteraction({ dfIntentName, dfFlowName, dfPageName }) {
    return dfFlowName ? `${dfFlowName} - ${dfPageName}` : dfIntentName;
  }

  cleanGraphicMessages() {
    this.dialog[this.dialog.length - 1].messages = this.dialog[this.dialog.length - 1].messages.filter(
      (m) => !(m.type === 'suggestions' || m.type === 'uploadFiles' || m.items?.some((i) => i.button.type === 'value'))
    );
  }
}
