import { Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserAccountService } from '@core/services/user-account.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[roleRestriction]',
})
export class RoleRestrictionDirective implements OnInit, OnDestroy {
  @Input() roleRestriction: Array<string> | string;
  @Input('roleRestrictionDisableElements') disableElements: boolean = false;

  containerView: EmbeddedViewRef<any>;
  nodeObserver: any;

  elementReadonly = ['input', 'textarea'];
  elementDisable = ['select', 'button', 'input'];

  accountSubcription: Subscription;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private userAccountService: UserAccountService) {}

  ngOnInit() {
    this.accountSubcription = this.userAccountService.sessionAccount$.subscribe(() => {
      this.checkRoleElements();
    });
  }

  ngOnDestroy() {
    this.accountSubcription?.unsubscribe();
    this.nodeObserver?.disconnect();
  }

  processElements(elementNodes: any) {
    if (this.elementReadonly.indexOf(String(elementNodes.nodeName).toLowerCase()) !== -1) {
      this.forbidElement(elementNodes, 'readonly');
    } else if (this.elementDisable.indexOf(String(elementNodes.nodeName).toLowerCase()) !== -1) {
      this.forbidElement(elementNodes, 'disabled');
    }

    elementNodes.querySelectorAll(this.elementReadonly.join(',')).forEach((element) => {
      this.forbidElement(element, 'readonly');
    });
    elementNodes.querySelectorAll(this.elementDisable.join(',')).forEach((element) => {
      this.forbidElement(element, 'disabled');
    });
  }

  forbidElement(elementNode: any, attributeType: 'readonly' | 'disabled') {
    if (!elementNode.hasAttribute(attributeType) && !elementNode.hasAttribute('disableexception')) elementNode.setAttribute(attributeType, '');
  }

  private checkRoleElements() {
    if (this.userAccountService.isAuthorized(this.roleRestriction) || this.disableElements) {
      if (!this.viewContainer.length) {
        this.containerView = this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.nodeObserver?.disconnect();
      }

      if (this.disableElements && !this.userAccountService.isAuthorized(this.roleRestriction)) {
        const elementNodes = this.containerView.rootNodes.find((node) => node.querySelectorAll);

        this.processElements(elementNodes);

        this.nodeObserver = new MutationObserver(() => {
          this.processElements(elementNodes);
        });

        this.nodeObserver.observe(elementNodes, {
          attributes: true,
          childList: true,
          characterData: true,
        });
      }
    } else {
      this.viewContainer.clear();
    }
  }
}
