import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { License } from 'src/app/models/license.model';
import { Products } from 'src/app/models/products.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  private onChangeLicense: BehaviorSubject<License> = new BehaviorSubject<License>(null);

  private license: License;

  constructor(private http: HttpClient) {}

  getSessionLicense(): Observable<License> {
    return this.onChangeLicense.asObservable().pipe(filter((license) => license != null));
  }

  setSessionLicense(license: License) {
    this.license = license;
    this.onChangeLicense.next(license);
  }

  getLicense(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/license`).pipe(
      tap((response: any) => {
        this.setSessionLicense(response);
      })
    );
  }

  isPurchased(itemCode: string, type: keyof Products): boolean {
    return this.license.products[type].find((item) => item.code === itemCode && item.purchased) !== undefined;
  }
}
