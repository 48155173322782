import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-salesforce',
  templateUrl: './salesforce.component.html',
  styleUrls: ['./salesforce.component.scss'],
})
export class SalesforceComponent implements OnInit {
  authCode;
  constructor(private route: ActivatedRoute, public commonService: CommonService) {}

  ngOnInit() {
    this.route.queryParams.subscribe(({ code }) => (this.authCode = code));
  }
}
