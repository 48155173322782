import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportModalComponent } from '@shared/components/support-modal/support-modal.component';
import { NotificationService } from '@shared/services/notification.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const excludedApiStatus: Array<any> = [
  {
    url: `${environment.API.orchestrator_configurator}/liveperson`,
    method: 'POST',
    status: [502],
  },
  {
    url: `${environment.API.orchestrator_configurator}/liveperson`,
    method: 'PUT',
    status: [502],
  },
  {
    url: `${environment.API.orchestrator_chat}/actuator/health`,
    method: 'GET',
  },
  {
    url: `${environment.API.orchestrator_analysis_v2}/actuator/health`,
    method: 'GET',
  },
  {
    url: `${environment.API.orchestrator_configurator}/actuator/health`,
    method: 'GET',
  },
  {
    url: `${environment.API.channel_whatsapp}/health-check/health-check`,
    method: 'GET',
  },
  {
    url: `${environment.API.channel_vivocha}`,
    method: 'POST',
  },
  {
    url: `${environment.API.channel_timrcc}/health-check/health-check`,
    method: 'GET',
  },
  {
    url: `${environment.API.channel_liveperson}/health-check/health-check`,
    method: 'GET',
  },
  {
    url: `${environment.API.channel_liveperson}/checkAgentsStatus`,
    method: 'GET',
    status: [400],
  },
  {
    url: `${environment.API.channel_facebook}/health-check/health-check`,
    method: 'GET',
    status: [500, 404],
  },
  {
    url: `${environment.API.channel_teams}/health-check/health-check`,
    method: 'GET',
  },
  {
    url: `${environment.API.channel_telegram}/health-check/health-check`,
    method: 'GET',
  },
  {
    url: `${environment.API.orchestrator_configurator}/liveperson/checkAgentsStatus`,
    method: 'GET',
  },
  {
    url: `${environment.API.orchestrator_analysis}/billing/dialogflowES`,
    method: 'GET',
  },
  {
    url: `${environment.API.orchestrator_analysis}/billing/dialogflowCX`,
    method: 'GET',
  },
  {
    url: `${environment.API.orchestrator_analysis}/billing/dlp`,
    method: 'GET',
  },
  {
    url: `${environment.API.orchestrator_analysis}/billing/naturalLanguage`,
    method: 'GET',
  },
  {
    url: `${environment.API.orchestrator_analysis}/billing/textToSpeech`,
    method: 'GET',
  },
  {
    url: `${environment.API.orchestrator_analysis}/billing/translate`,
    method: 'GET',
  },
  {
    url: `${environment.API.orchestrator_configurator}/untrustedConfiguration`,
    method: 'GET',
    status: [404],
  },
  {
    url: `${environment.API.orchestrator_configurator}/entities/`,
    method: 'GET',
    status: [400],
  },
  {
    url: `/`,
    method: 'GET',
    status: [304, 200],
  },
  {
    url: `${environment.keycloak_issuer}/protocol/openid-connect/token`,
    method: 'POST',
  },
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private modalService: NgbModal) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!this.excludeApi(req, error)) {
          if (error.status !== 403 && error.status !== 401 && error.error !== 'SESSION') {
            if (error instanceof HttpErrorResponse && error.error instanceof Blob && error.error.type === 'application/json') {
              (error.error as any).text().then((err) => {
                const message = JSON.parse(err).message || 'Generic Error';
                this.showModal({
                  message,
                  code: error.status,
                });
              });
            } else {
              this.showModal({
                message: error && error.error.message ? error.error.message : 'Generic Error',
                code: error.status,
              });
            }
          }
        }
        return throwError(error);
      })
    );
  }

  private showModal(serviceError: { message: string; code: number }) {
    this.notificationService
      .openModal({
        title: 'ERROR',
        message: 'Ops... Something went wrong and it was impossible to complete the operation!',
        type: 'danger',
        choice: 'support',
        serviceError,
      })
      .subscribe(async (confirm: boolean) => {
        if (!confirm) return;

        this.modalService.open(SupportModalComponent, {
          size: 'lg',
        });
      });
  }
  private excludeApi(req: HttpRequest<any>, error: HttpErrorResponse) {
    return excludedApiStatus.find((api) => {
      if (
        (req.url === api.url || req.url.substring(0, req.url.lastIndexOf('/') + 1) === api.url.substring(0, api.url.lastIndexOf('/') + 1)) &&
        req.method === api.method &&
        (!api.status || api.status.length === 0 || api.status.indexOf(error.status) >= 0)
      ) {
        return true;
      } else {
        return false;
      }
    });
  }
}
