import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const interceptHandlers: Array<string> = [`${environment.API.orchestrator_configurator}/personality/result`];

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (interceptHandlers.indexOf(req.url) !== -1 && !req.params.has('lang')) {
      return next.handle(
        req.clone({
          params: req.params.set('lang', this.translateService.currentLang || this.translateService.getDefaultLang()),
        })
      );
    }

    return next.handle(req);
  }
}
