import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@shared/services/notification.service';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss'],
})
export class SupportModalComponent implements OnDestroy {
  @ViewChild('supportForm', { static: true }) supportForm: NgForm;
  @Input() support?: any = {};

  supportSubscription: Subscription;

  constructor(private dashboardService: DashboardService, public activeModal: NgbActiveModal, private notificationService: NotificationService) {}

  ngOnDestroy() {
    if (this.supportSubscription instanceof Subscription) this.supportSubscription.unsubscribe();
  }

  sendRequest() {
    if (this.supportForm.invalid) return;

    this.supportSubscription = this.dashboardService.sendSupportRequest(this.support).subscribe(() => {
      this.activeModal.close();
      this.notificationService.showToast('Request correctly sended', { type: 'success' });
    });
  }
}
