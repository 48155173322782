import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { JourneyService } from '@core/services/journey.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { Journey } from 'src/app/models/journey.model';
import { OrchestrateMessage } from 'src/app/models/orchestrate-message.model';
import { Chatbot } from '../../classes/chatbot';
import { ChatService } from '../../services/chat.service';
import { ChatDebugModalComponent } from '../modals/chat-debug-modal/chat-debug-modal.component';
import { ChatSettingsModalComponent } from '../modals/chat-settings-modal/chat-settings-modal.component';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatComponent implements OnInit, OnDestroy, OnChanges {
  @Output() switchChat = new EventEmitter<any>();
  @Input() openChat: boolean;

  subscriptions: Object = {};

  supportJourney;
  activeJourney: Journey;
  botSession: Chatbot;

  cardCarouselOptions: OwlOptions = {
    items: 2,
    dots: false,
    margin: 10,
    autoWidth: true,
  };

  chipsCarouselOptions: OwlOptions = {
    items: 2,
    dots: true,
    margin: 10,
    mouseDrag: true,
  };
  dragging = false;
  onDragging({ dragging }) {
    setTimeout(() => (this.dragging = dragging));
  }
  agentLang: string;
  get disableChat() {
    if (this.botSession.dialog.length > 0) {
      const lastInteraction = this.botSession.dialog[this.botSession.dialog.length - 1];
      return lastInteraction.messages.some((m) => m.items?.[0].disableChat);
    }
  }
  constructor(private chatService: ChatService, private modalService: NgbModal, private journeyService: JourneyService) {}

  ngOnInit() {
    this.subscriptions['SessionJourney'] = this.journeyService.journey$.subscribe((journey) => {
      if (this.subscriptions['AgentLanguage'] instanceof Subscription) this.subscriptions['AgentLanguage'].unsubscribe();

      this.subscriptions['AgentLanguage'] = this.journeyService.getAgentLang$().subscribe((lang) => {
        this.agentLang = lang;
        this.activeJourney = journey;
        if (this.openChat) {
          this.initChatSession();
        } else if (this.botSession instanceof Chatbot && this.botSession.sessionKey !== this.activeJourney.apiKey) {
          this.botSession = null;
        }
      });
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngOnChanges(changes: any) {
    if (changes.openChat.currentValue && !(this.botSession instanceof Chatbot)) this.initChatSession();
  }

  initChatSession(sessionSettings?) {
    this.botSession = new Chatbot(this.activeJourney.apiKey, this.agentLang, sessionSettings);

    if (this.journeyService.journey.dispatcher && !sessionSettings) {
      this.editCustomData();
    } else {
      this.botSession.inputEvent = 'Welcome';
      this.submitEvent();
    }

    this.subscriptions['BotRestore'] = this.botSession.onRestoreSession.subscribe((response) => this.initChatSession(response));
    this.subscriptions['BotRecording'] = this.botSession.onStopRecording.subscribe(() => this.submitAudio());
  }

  toggleChat(openStatus: boolean) {
    this.openChat = !openStatus;
    this.switchChat.emit(this.openChat);
  }
  switchJourneyType;

  submitText(inputLabel?: string) {
    this.botSession.cleanGraphicMessages();
    this.botSession.updateDialog('user', [{ type: 'text', text: inputLabel || this.botSession.inputMessage }]);
    this.sendMessage({ userMessage: this.botSession.inputMessage });
  }

  submitEvent() {
    this.botSession.updateDialog('user', [{ type: 'event', event: this.botSession.inputEvent }]);
    this.sendMessage({
      event: { name: this.botSession.inputEvent },
    });
  }

  submitFiles() {
    this.botSession.updateDialog('user', [{ type: 'event', event: this.botSession.inputEvent }]);
    this.sendMessage({ files: Object.values(this.botSession.files), event: { name: this.botSession.inputEvent } });
  }

  submitAudio() {
    this.sendMessage({ audio: this.botSession.audioBase64Input });
  }

  sendMessage(params: any) {
    const data = {
      lang: this.botSession.sessionLang,
      sessionId: this.botSession.sessionId,
      audioOutputRequired: this.botSession.outputAudio,
      journeyCode: this.botSession.journeyCode || null,
      query: params.userMessage || null,
      queryParametersMap: params.userMessage ? this.botSession.formattedParametersMap : null,
      event: params.event ? this.botSession.formattedEvent : null,
      audio: params.audio || null,
      files: params.files || null,
      userData: Object.keys(this.botSession.sessionUserData).length ? this.botSession.sessionUserData : null,
      contexts: this.botSession.formattedContexts,
    };

    if (this.activeJourney.fulfillment) {
      this.subscriptions['ChatSubscription'] = this.chatService.sendFulfillmentMessage(this.botSession.sessionKey, data).subscribe(
        (response) => this.botSession.processFulfillmentResponse(data, response),
        () => this.botSession.clearUserInput()
      );
    } else {
      this.subscriptions['ChatSubscription'] = this.chatService.sendMessage(this.botSession.sessionKey, data).subscribe(
        (response) => this.botSession.processOrchestrateResponse(data, response, !!params.audio),
        () => this.botSession.clearUserInput()
      );
    }
  }

  speechRecording() {
    if (this.botSession.recorder) {
      this.botSession.stopRecording();
      return;
    }

    this.botSession.startRecording();
  }

  viewDebug(interaction) {
    const modalRef = this.modalService.open(ChatDebugModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.dialog = this.botSession.dialog;
    modalRef.componentInstance.selectedInteraction = interaction;
  }

  editCustomData() {
    const modalRef = this.modalService.open(ChatSettingsModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.botSession = this.botSession;
  }

  selectGraphicElement(buttonType: string, graphicItem: any, graphicElements: OrchestrateMessage) {
    let valueLabel: string;
    this.botSession.inputMessage = graphicItem.value;

    switch (graphicElements.type) {
      case 'suggestions':
        valueLabel = graphicItem.title;
        break;
      case 'card':
      case 'list':
        if (buttonType === 'disabled') return;
        if (graphicItem.url) {
          window.open(graphicItem.url);
          return;
        }
        valueLabel = graphicItem.value_name;
        break;
    }

    this.submitText(valueLabel);
  }

  uploadFile(target, fileMessage, fileIndex) {
    const files = (target as HTMLInputElement).files;
    if (!files?.[0]) return;
    const file = files[0];
    const fileData = fileMessage.files[fileIndex];
    if (file.size > 1024 * 1024 * parseFloat(fileData.maxSize)) {
      fileData.error = `The uploaded file size cannot exceed ${fileData.maxSize}MB`;
      return;
    }
    fileData.title = file.name;
    this.chatService.uploadFile(this.botSession.sessionKey, this.botSession.sessionId, file).subscribe(
      (event) => {
        switch (event.type) {
          case HttpEventType.Sent:
          case HttpEventType.UploadProgress:
            fileData.progress = 10 + event.type * Math.round((event.loaded / event.total) * 70);
            break;
          case HttpEventType.Response:
            fileData.progress = 100;
            fileData.error = null;
            this.botSession.files[fileIndex] = {
              name: file.name,
              url: event.body.url,
              contentType: event.body.contentType,
            };
            if (fileMessage.files.length === Object.values(this.botSession.files).length) {
              this.botSession.inputEvent = fileMessage.event;
              this.submitFiles();
            }
            setTimeout(() => {
              fileData.progress = 0;
              fileData.uploaded = true;
            }, 500);
        }
      },
      (err) => {
        fileData.progress = 0;
        fileData.error = err.message;
      }
    );
  }

  nextGraphicElements(interactionIndex, message: OrchestrateMessage) {
    const newMessage = { ...message, firstItem: (message.firstItem || 0) + message.pageSize };
    this.botSession.addInteractionMessage(interactionIndex, newMessage);
  }

  showNextButton(message) {
    return message.paginated && message.items?.length > (message.firstItem || 0) + message.pageSize;
  }
}
